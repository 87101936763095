.section-newsletter {
    @apply text-center bg-orange-800 text-white;
}

.button-newsletter {
    @apply px-3 py-2 text-white text-sm border-2 border-white;
}

.newsletter-copy {
    @apply max-w-11/12 mx-auto;
    @screen md {
        @apply max-w-xl px-6;
    }
}

.newsletter-responses {
    @apply mt-4;
    a {
        @apply text-white;
    }
}

;@import "sass-embedded-legacy-load-done:7";