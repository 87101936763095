$env: development;
@tailwind base;

@tailwind components;

@import "common/common";

// global styles on the most common elements
body {
    @apply font-body leading-normal text-gray-800;
}

h1, h2, h3, h4, h5, h6 {
    @apply leading-tight text-orange-800;
}

h1, h2, h3 {
    @apply font-display mb-2;
}

h1 {
    @screen lg {
        @apply text-5xl;
    }
}

h2 {
    @apply text-3xl;
    @screen lg {
        @apply text-4xl;
    }
}

h3 {
    @apply mt-8 mb-2;
    @screen lg {
        @apply text-3xl;
    }
}

h4 {
    @apply mt-8 mb-2;
}

p {
    @apply my-4;
    &:first-of-type {
        @apply mt-0;
    }
    &:last-of-type {
        @apply mb-0;
    }
}

h3 + p, h4 + p {
    @apply mt-0;
}

a {
    @apply text-orange-700;
    &:hover {
        @apply text-orange-800;
    }
}

ul, ol {
    li {
        @apply mb-4;
        &:last-of-type {
            @apply mb-0;
        }
    }
}

.w-content {
    @apply max-w-11/12 mx-auto;
    @screen xl {
        @apply max-w-6xl;
    }
}

.w-content-narrow {
    @apply max-w-11/12 mx-auto;
    @screen md {
        @apply max-w-xl;
    }
    @screen xl {
        @apply max-w-2xl;
    }
}

.w-content-copy {
    @apply max-w-11/12 mx-auto;
    @screen md {
        @apply max-w-md;
    }
    @screen xl {
        @apply max-w-xl;
    }
}

// common styles
@import "common/alerts",
    "common/content_alignment",
    "common/forms";

// public only styles
@import
    "public/banner",
    "public/buttons",
    "public/header",
    "public/newsletter",
    "public/section";

//shop styles
@import
    "public/cart",
    "public/checkout",
    "public/products_list",
    "public/product_view",
    "public/woocommerce";

// page specific
@import "public/p_index",
"public/p_contact",
"public/p_wine_club";

// wp editor styles
@import
"public/wp_editor";

@tailwind utilities;
