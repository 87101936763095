.checkout-wrap {
    h3 {
        @apply mt-0;
    }

    .product-total {
        @apply text-right;
    }
}

.woocommerce-checkout-payment {
    @apply max-w-md mx-auto;
}

.woocommerce-billing-fields {
    @apply mb-8;
    @screen lg {
        @apply mb-0;
    }
}

.woocommerce-additional-fields {
    @apply my-8;
}

.checkout-coupon {
    @apply text-right;
    a {
        @apply block text-sm;
    }
}

.checkout_coupon .button {
   @apply text-base py-2 px-4;
}

.coupon input {
    @apply w-48 mr-2;
}

//Stripe

.woocommerce-checkout #payment ul.payment_methods li img.stripe-icon {
    @apply float-left;
}

.woocommerce-checkout #payment div.payment_box {
    // @apply mt-12;
}

;@import "sass-embedded-legacy-load-done:10";