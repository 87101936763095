.banner {
    @apply bg-cover bg-center w-full;
    h1, h4 {
        @apply text-white;
    }
    &-hp {
        @apply flex w-full bg-cover bg-bottom z-0;
        background-image: url('/images/herobar-hp.jpg');
        @screen xl {
            height: 45rem;
        }
    }

    &-contact, &-about {
        background-image: url('/images/mocojo-vineyards.jpg');
    }
    &-main {
        background-image: url('/images/mocojo-barrel.jpg');
    }
    &-shop {
        background-image: url('/images/herobar-hp.jpg');
    }

}

.banner-inner {
    @apply flex flex-col items-center w-full h-full py-24 text-center text-white;
    background-color: rgba(0,0,0,0.5);
    @screen lg {
        @apply py-32;
    }
    &-hp {
        @apply flex-wrap justify-center mx-auto text-center;
        background-color: rgba(0,0,0,0.3);
    }
}

.banner-heading {
    @apply text-3xl;
    @screen md {
        @apply text-6xl;
    }
}

.banner-subheading {
    @apply text-xl;
    @screen md {
        @apply text-3xl;
    }
}
.banner-heading-hp {
    @apply w-2/3 xl:w-[55%] mb-6 text-4xl text-white text-center leading-tight ;

    @screen xl {
        font-size: 5rem;
    }
}

;@import "sass-embedded-legacy-load-done:4";