.cart-wrap {
    .woocommerce {
        @apply max-w-11/12 lg:max-w-3xl mx-auto;
    }
    h1 {
        @apply mb-6;
    }
}

.cart_item .woocommerce-Price-amount {
    @apply text-base;
}

.cart_item .qty {
    @apply text-right;
    max-width: 5rem;
}

.woocommerce-cart .cart-collaterals .cart_totals table {
    @apply mt-4 ml-auto;
}

.woocommerce-cart .wc-proceed-to-checkout a.checkout-button {
    @apply text-base ml-auto;
    width: 15rem;
}

.woocommerce .cart-collaterals .cart_totals, .woocommerce-page .cart-collaterals .cart_totals {
    @apply float-none w-full;
}

.cart_totals .shop_table {
    @apply ml-auto;
}

.woocommerce table.shop_table {
    @apply max-w-full;
}

.cart-subtotal td, .order-total td, tr.tax-rate td, .woocommerce-shipping-totals.shipping td {
    @apply text-right;
}

.orderby {
    @apply hidden;
}

.main-cart .woocommerce {
    @apply w-full;
}

.main-cart .woocommerce-Price-amount {
    @apply text-xl;
}

.coupon-link {
    @apply p-0 bg-transparent hover:bg-transparent leading-none rounded-none text-orange-700 hover:text-orange-900 text-sm;
}

.cart_total-coupon {
    @apply text-right;
    a {
        @apply block text-sm;
    }
}

.product-quantity, .product-subtotal {
    @apply text-right;
}

.cart .button {
    @apply text-sm py-2 px-4;
}

;@import "sass-embedded-legacy-load-done:9";