.button, a.button, [type=submit], .woocommerce a.button, .woocommerce a.button.alt, .woocommerce button.button.alt {
    @apply inline-block px-6 py-4 text-white bg-orange-800 leading-none rounded no-underline;
    transition: all 0.25s ease-in;
    &:hover {
        @apply text-white bg-orange-900;
    }
    @screen sm {
        @apply text-lg font-normal;
    }
}

.button-lg {
    @apply px-8 py-6;
    @screen sm {
        @apply text-2xl;
    }
}

.button-menu, a.button-menu, .woocommerce a.button-menu {
    @apply flex justify-center items-center w-40 mx-auto;
    @screen sm {
        @apply text-lg h-16;
    }
    @screen lg {
        @apply  w-auto mx-0 text-xl;
    }
}

;@import "sass-embedded-legacy-load-done:5";