.product_title {
    @apply text-5xl leading-none mb-4;
}
.woocommerce-Price-amount {
    font-size: 2rem;
    color: rgb(44,82,130);
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 2.5;
}
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__arrow,
.input-text .qty .text {
    height: 2.5rem;
}
button, .button, .woocommerce a.button, .woocommerce button.button.alt {
    padding: 0.75rem 1.25rem;
}
.woocommerce .quantity .qty {
    @apply w-20;
}
.product_meta {
    @apply hidden;
}
.summary .cart {
    @apply flex items-center;
}
.related {
    clear: both;
}
.related h2 {
    @apply mb-4;
}
.related .button.product_type_simple.add_to_cart_button {
    @apply text-xs;
}
.woocommerce ul.products li.product .woocommerce-loop-product__title {
    @apply text-2xl p-0;
}
.woocommerce-wrap h2.woocommerce-loop-product__title {
    font-size: 1.75rem !important;
    padding: 0 0 0.25rem 0 !important;
}
.price .woocommerce-Price-amount {
    @apply text-base font-bold;
}
.product_summary-wrap .price {
    del .amount {
        @apply text-gray-600 font-normal opacity-70;
    }
    ins {
        @apply no-underline;
    }
}
.price-single-product > .woocommerce-Price-amount {
    font-size: 2rem;
}
.woocommerce-wrap .woocommerce ul.products li.product .button {
    margin-top: 0.5rem !important;
}
.hp-products .woocommerce ul.products li.product .button {
    @apply text-sm;
}
.woocommerce-cart table.cart img  {
    min-width: 4.5rem;
}
.product-remove {
    @apply pr-2;
    font-size: 2rem;
}
.summary {
    @apply py-12 pr-12;
}

#order_review_heading {
    @apply mt-8 text-center;
}
.woocommerce-checkout #payment {
    @apply max-w-lg mx-auto;
}

.woocommerce ul.order_details {
    @apply mt-12;
}

.cart .woocommerce-Price-amount, .checkout .woocommerce-Price-amount {
    @apply text-base;
}

.woocommerce a.remove {
    @apply text-2xl;
}

.woocommerce-page .stock {
    @apply mt-12 text-center;
}

.woocommerce span.onsale {
    @apply p-0 bg-orange-800;
}

;@import "sass-embedded-legacy-load-done:13";