.hp-banner-heading {
    @apply w-2/3 mb-16 text-4xl text-white text-left leading-tight;
    @screen lg {
        @apply w-1/2;
        font-size: 5rem;
    }
}

.hp-intro-wrap {
    @apply justify-between max-w-11/12 mx-auto bg-white;
    width: 30rem;
    @screen lg {
        @apply flex w-auto;
    }
    @screen xl {
        @apply max-w-6xl;
    }
}

;@import "sass-embedded-legacy-load-done:14";