.radios-wine_club-wrap {
    @apply my-8 p-8 pb-4 border-2 border-gray-800 rounded;
    .wpcf7-list-item {
        @apply mb-4;
        &:last-of-type {
            @apply mb-0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:16";