.section-orange {
    @apply  px-4 xl:px-0 py-24 bg-orange-800 text-white;
    a {
        @apply text-white underline;
        &:hover, &:active {
            @apply underline text-white;
        }
    }
}

;@import "sass-embedded-legacy-load-done:8";