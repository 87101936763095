.header-nav {
    @apply font-display max-w-11/12 mx-auto py-5;
    @screen lg {
        @apply flex flex-wrap justify-between items-center py-0;
    }
    @screen xl {
        @apply max-w-6xl;
    }
}

.header-nav_list {
    @apply flex flex-col justify-center w-full mt-6 mb-4 p-0 text-center list-none;
    @screen xs {
        //@apply ;
    }
    @screen sm {
        @apply flex-row mb-4 text-right;
    }
    @screen md {
        @apply mt-2;
    }
    @screen lg {
        @apply w-auto ml-auto mt-0 mb-0 mr-12 text-base;
    }
    @screen xl {
        @apply text-xl;
    }
}

.header-nav_item {
    @apply mb-4;
    @screen sm {
        @apply mx-3 mb-2;
    }
    @screen lg {
        @apply mr-8 ml-0 mb-0;
    }
    @screen xl {
        @apply mr-12;
    }
}

;@import "sass-embedded-legacy-load-done:6";