/*stylelint-disable no-descending-specificity*/

.products {
    @apply mt-4;
}

.products a.button {
    @apply text-sm;
}

.product {
    @apply mb-0;
}

.woocommerce ul.products li.product .price {
    @apply text-lg text-gray-600 font-bold;
}

.woocommerce-page ul.products li.product,
.woocommerce ul.products[class*=columns-] li.product,
.woocommerce-page ul.products[class*=columns-] li.product {
    @apply w-4/5 mx-auto;

    @screen sm {
        @apply mt-0 mr-8 mb-12 ml-0;
        width: 47%;
        &:nth-of-type(2n) {
            @apply mr-0;
        }
    }
    @media (min-width: 785px) {
        width: 47.3%;
    }
    @screen lg {
        width: 30.5%;
        &:nth-of-type(3n) {
            @apply mr-0;
        }
        &:nth-of-type(2n) {
            @apply mr-8;
        }
    }
    @screen xl {
        @apply mt-0 mr-8 mb-12 ml-0;
        width: 31.4%;
    }
}

.hp-products {
    @apply mx-auto my-0;
    .products {
        @apply flex flex-wrap justify-between;
    }
    .woocommerce ul.products li.product {
        @apply mx-auto my-6;
        width: 30%;
    }
}

.woocommerce ul.products.columns-3 li.product,
.woocommerce-page ul.products.columns-3 li.product {
    width: 31.25%;
}

.main-shop ul.products,
.main-shop .woocommerce ul.products {
    @apply flex flex-wrap;
}
.main-shop ul.products li.product:nth-of-type(3n) {
    @apply mr-auto;
    @screen lg {
        &:nth-of-type(3n) {
            @apply mr-0;
        }
    }
}
.main-shop .woocommerce ul.products li.product:nth-of-type(3n) {
    @apply mr-0;
}

.related_products-wrap {
   .product {
        @screen lg {
            margin-bottom: 0 !important;
        }
    }
}

.woocommerce ul.products.columns-3 li.product:last-of-type,
.woocommerce-page ul.products.columns-3 li.product:last-of-type {
    @apply mr-0;
}

.woocommerce ul.products::before {
    @apply hidden;
}

.woocommerce ul.products li.product .woocommerce-loop-product__title {
    @apply pt-0;
    font-size: 1.75em;
}

.woocommerce ul.products li.product .button {
    @apply mr-4;
}

;@import "sass-embedded-legacy-load-done:11";