/// Single Product Page

.product_summary-wrap {
    @apply mt-8 p-8 bg-white;
    @screen md {
        @apply w-2/3 mt-0;
    }
    h1 {
        @apply my-0 text-xl;
        @screen lg {
            @apply text-4xl;
        }
    }
    ul {
        @apply mb-4;
    }
    form {
        @apply flex flex-col sm:flex-row md:flex-col lg:flex-row justify-end items-center mt-12;
    }
    .price-single-product {
        @apply mt-0;

    }
    .amount {
        @apply text-lg font-bold text-orange-700;
         @screen lg {
            @apply text-3xl;
        }
    }
    .quantity {
        @apply mr-6 mb-8 sm:mb-0 md:mb-8 lg:mb-0;
        &::before {
            content: 'Qty ';
        }
    }
    .product_meta {
       @apply hidden;
    }
}

.product-img-wrap {
    @apply max-w-[350px] lg:max-w-[auto] xl:min-w-[37.5rem] mx-auto md:ml-0 md:mr-4 p-4 bg-gray-900;

    .woocommerce-product-gallery__trigger {
        @apply hidden;
    }
}


ol.flex-control-thumbs {
    @apply list-none grid grid-cols-4 gap-2 mt-2 pl-0;
    li {
        @apply mb-0;
    }
}

;@import "sass-embedded-legacy-load-done:12";